import { render, staticRenderFns } from "./CallRecordsDownloadPolicy.vue?vue&type=template&id=6056475d&scoped=true"
import script from "./CallRecordsDownloadPolicy.vue?vue&type=script&lang=js"
export * from "./CallRecordsDownloadPolicy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6056475d",
  null
  
)

component.options.__file = "CallRecordsDownloadPolicy.vue"
export default component.exports